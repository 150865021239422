import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Campaign } from 'src/app/models/campaign.model';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { CreatorApproval } from 'src/app/models/CreatorApproval.model';
import { Subscription, interval } from 'rxjs';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { CampaignService } from 'src/app/services/campaign.service';
import { ActivatedRoute } from '@angular/router';
import { CampaignScenario } from 'src/app/models/campaign.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmComponent } from 'src/app/modules/shared/dialog-confirm/dialog-confirm.component';
import { is } from 'date-fns/locale';
import { DomSanitizer } from '@angular/platform-browser';
import { CampaignPresentation } from 'src/app/models/campaignPresentation.model';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-campaign-commercial-proposal3',
  templateUrl: './campaign-commercial-proposal3.component.html',
  styleUrls: ['./campaign-commercial-proposal3.component.scss'],
})
export class CampaignCommercialProposal3Component implements OnInit {
 
  @Input() campaign: Campaign;
  successCasesNames: string[] = [];
  successCases: string[]  = [];
  isEdit = false;
  isLoading = false;
  presentationUrl: string;
  presentationUrlPDF: string;
 
  constructor(
    private modalService: NgbModal,
    private campaignService: CampaignService,
    private readonly dialog: MatDialog,
    private appService: AppService,
    private environmentService: EnvironmentService,
  ) {
    this.successCasesNames = this.appService.configBackend.campaign_presentation_success_cases;
  }

  ngOnInit() {
    this.presentationUrl = this.environmentService.getInfluencersURI().replace('/api/auth/', '') + '/campaign-presentation/' + this.campaign.id;
    this.presentationUrlPDF = this.presentationUrl + '/ppt';
    if (this.getPresentationId()) {
      this.resetSelectedCases();
    } else {
      this.setEdit(true);
    }
  }

  close() {
    this.modalService.dismissAll();
  }

  setEdit(value: boolean) {
    this.isEdit = value;
    this.resetSelectedCases();
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  getPresentationId() {
    return this.campaign.campaignPresentation?.id;
  }

  resetSelectedCases() {
    this.successCases = (this.campaign.campaignPresentation?.success_cases || []).map(x => x);
  }

  isChecked(value: string) {
    return this.successCases.includes(value);
  }

  checkBoxChecked(checked: boolean, name: string) {
    if (checked) {
      this.successCases.push(name);
    } else {
      this.successCases = this.successCases.filter((sc) => sc !== name);
    }
  }

  create() {
    this.isLoading = true;
    const data = { 'success_cases': this.successCases };
    this.campaignService
      .createPresentation2(this.campaign, data)
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.setEdit(false);
          this.campaign.campaignPresentation = res;
          this.resetSelectedCases();
        }, (error) => {
          this.isLoading = false;
        });
  }


  update() {    
    this.isLoading = true;
    const data = { 'success_cases': this.successCases };
    this.campaignService
      .updatePresentation2(this.campaign, this.campaign.campaignPresentation, data)
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.setEdit(false);
          this.campaign.campaignPresentation = res;
          this.resetSelectedCases();
        }, (error) => {
          this.isLoading = false;
        });
  }

  updateConfig() {
    if (this.campaign.campaignPresentation) {
      const successCasesCampaign = this.campaign.campaignPresentation.success_cases.sort().join(',');
      const successCasesEdit = this.successCases.sort().join(',');
      if(successCasesCampaign == successCasesEdit){
        this.setEdit(false);
        return;
      }
      this.update();
    } else {
      this.create();
    }
  }

  downloadPPT() {
    window.open(this.presentationUrlPDF, '_blank');
  }

}
