import { Component, OnInit, Input, ViewChild, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import { Submission } from '../../../../models/submission.model';
import { CreatorApproval, CreatorApprovalPlanner } from '../../../../models/CreatorApproval.model';
import { isSameDay } from 'date-fns';
import { CalendarViewType } from '../../../../enum/calendar-view-type.enum';
import { BehaviorSubject } from 'rxjs';
import { SubmissionDialogComponent } from '../submission-dialog/submission-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Campaign } from '../../../../models/campaign.model';
import { CreatorPlannerContent } from 'src/app/models/CreatorPlannerContent.model';
import { Creator } from 'src/app/models/creator.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSelectChange } from '@angular/material/select';
import { ContentPlannerDialogComponent } from '../content-planner-dialog/content-planner-dialog.component';

enum CalendarTypes {
  ALL = 'all',
  SUBMISSIONS = 'submissions',
  PLANNERS = 'planners',
}

type Item = Submission | CreatorPlannerContent;

@Component({
  selector: 'app-campaign-calendar',
  styleUrls: ['./campaign-calendar.component.scss'],
  templateUrl: './campaign-calendar.component.html'
})
export class CampaignCalendarComponent implements OnInit {
  @Input() public campaignSubmissions: Array<Submission>;
  @Input() public campaignPlannerContents: Array<CreatorPlannerContent>;
  @Input() campaign: Campaign;
  @Input() public creatorApprovals: Array<CreatorApproval>;
  @Input() public currentSub: Submission = null; // Used to know if we are in submission modal
  @Output() public datePicked: EventEmitter<Date> = new EventEmitter();
  @Output() public closeCalendar = new EventEmitter<void>();
  @ViewChild('modal') modal;
  public creatorApprovalsList: Array<CreatorApproval> = [];
  public submissions: Submission[] = [];
  public contents: CreatorPlannerContent[] = [];
  public calendarTypeFilter = new BehaviorSubject<CalendarTypes>(CalendarTypes.ALL);
  public creatorsFilter = new BehaviorSubject<Array<CreatorApproval>>([]); 
  public calendarList = new BehaviorSubject<Item[]>([]);
  public slideNavList = new BehaviorSubject<Item[]>([]);
  public slideNavDatePicked: string;
  public lastDate = new Date();
  public firstDate = new Date();
  public showFiller: boolean = false;
  public sideNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public view: BehaviorSubject<CalendarViewType> = new BehaviorSubject<CalendarViewType>(CalendarViewType.Month);
  public viewDate: Date = new Date();
  public events: Array<CalendarEvent>;
  public activeDayIsOpen: boolean = false;
  public sub: Submission = null;
  public creator: CreatorApproval;
  private seed: number = 0;
  public tentativeDate: CalendarEvent = null;
  public locale = navigator.language;

  colors = {
    submission: { primary: '#7400E7', secondary: 'rgb(246 241 251)' },
    planner: { primary: 'rgb(235 138 100)', secondary: 'rgb(255 246 241)' },
  };

  constructor(public dialog: MatDialog) { }

  public ngOnInit(): void {
    let isSetup = true;
    this.creatorApprovalsList = this.campaign.isApproved() ? 
      this.creatorApprovals.filter(x => x.isConfirmed()) : 
      this.creatorApprovals;
    this.submissions = this.campaignSubmissions
      .filter(x => 
        x.status === 'Approved' 
        && x.dateToPost 
        && this.creatorApprovalsList.find(y => y.userId == x.userId) 
      );
    this.contents = this.campaignPlannerContents.filter(plannerContent => 
      plannerContent.postAt
      && this.creatorApprovalsList.find(x => x.id == plannerContent.userApprovalId));
    this.creatorsFilter.next(this.creatorApprovalsList);
    this.calendarTypeFilter.next(CalendarTypes.ALL);
    this.creatorsFilter.subscribe({next: (value) => !isSetup && this.onCreatorsFilterChanges(value)});
    this.calendarTypeFilter.subscribe({next: (value) => !isSetup && this.onCalendarTypeFilterChanges(value)});
    this.calendarList.subscribe({next: (value) => !isSetup && this.onCalendarListChanges(value)});
    isSetup = false;
    this.makeCalendar();
  }

  makeCalendar() {
    // this.sideNavOpen.next(false);
    let list = [].concat(this.submissions).concat(this.contents);
    list = this.filterListByCalendarType(list, this.calendarTypeFilter.value);
    list = this.filterListByCreatorIdType(list, this.creatorsFilter.value);
    list = this.sortListByDate(list);
    this.calendarList.next(list);
    this.setCalendarEvents();
  }

  filterListByCalendarType(list: Item[], type: CalendarTypes) : Item[] {
    return list.filter(item => 
      type == CalendarTypes.SUBMISSIONS ? item instanceof Submission :
      type == CalendarTypes.PLANNERS ? item instanceof CreatorPlannerContent 
      : true);
  }

  filterListByCreatorIdType(list: Item[], creatorApprovals: CreatorApproval[]) : Item[] {
    const creatorIds = new Set(creatorApprovals.map(creatorApproval => creatorApproval.userId));
    return list.filter(item => creatorIds.has( this.getUserId(item) ) );
  }

  sortListByDate(list: Item[]) : Item[] {
    return list.sort((a, b) => this.getStartDate(a) < this.getStartDate(b) ? -1 : 1);
  }

  public selectCalendar(value: CalendarTypes) {
    this.calendarTypeFilter.next(value);
  }

  public selectedCreator($event): void {
    const creatorsApprovalFiltered = ($event instanceof Creator) ?
      this.creatorApprovalsList.filter(creatorApproval => creatorApproval.userId === $event.id)
      : this.creatorApprovalsList; 
    this.creatorsFilter.next(creatorsApprovalFiltered);
  }

  public onCalendarListChanges(value: Item[]) {
    if (!value.length) {
      return;
    }
    this.firstDate = this.getStartDate(value[0]);
    this.lastDate = this.getStartDate(value[value.length - 1]);
  }

  public onCalendarTypeFilterChanges(value: CalendarTypes) {
    this.makeCalendar();
  }

  public onCreatorsFilterChanges(value: CreatorApproval[]) {
    this.makeCalendar();
  }

  getUserId(item: CreatorPlannerContent | Submission) : number {
    return item instanceof Submission ? item.userId : this.getCreatorApproval(item).userId;
  }

  getCreatorApproval(item: CreatorPlannerContent | Submission) : CreatorApproval {
    return this.creatorApprovalsList.find(creatorApproval => item instanceof Submission ?
        creatorApproval.userId === item.userId :
        creatorApproval.id === item.userApprovalId);
  }

  getCreatorSocialName(sub: Submission): string {
    return this.getCreatorApproval(sub).creator.getSocialName();
  }

  getStartDate(item: Item): Date {
    return item instanceof Submission ? item.getStartsAtDate() : item.getPostAtDate();
  }

  getStartDateString(item: Item) : string {
    return this.getStartDate(item).toISOString().split('T')[0];
  }

  getStartDateId(item: Item): string {
    return this.getStartDateString(item).replace(/\-/g, '_');
  }

  viewDateToString() {
    return this.viewDate.toISOString().split('T')[0];
  }


  public switchView(x : CalendarViewType): void {
    this.view.next(x);
    this.refresh();
  }

  private setCalendarEvents(): void {
    if (this.calendarList.value.length === 0) {
      this.events = [];
      return;
    }
    this.events = this.calendarList.value.map((item): CalendarEvent => (item instanceof Submission ?
        {
          title: this.getDisplayTextSubmission(item),
          start: new Date( item.dateToPost + 'T' + item.timeToPostStart + ':00' ),
          color: this.colors.submission,
          meta: {
            item: item,
            isSubmission: true,
            isPlanner: false,
            creatorApproval: this.getCreatorApproval(item),
            userId: item.userId,
          }
        } : {
          title: this.getDisplayTextPlannerContent(item),
          start: new Date( item.postAt),
          allDay: true,
          color: this.colors.planner,
          meta: {
            item: item,
            isSubmission: false,
            isPlanner: true,
            creatorApproval: this.getCreatorApproval(item),
            userId: this.getCreatorApproval(item).userId,
          }
        }))
        .sort((x, y) => x.meta.userId > y.meta.userId ? 1 : -1);
  }

  navItemClicked(item: Item) {
    this.viewDate = this.getStartDate(item);
    this.refresh();
  }

  public dayClicked({date, events}: { date: Date; events: Array<CalendarEvent> }): void {
    const id = date.toISOString().split('T')[0].replace(/\-/g, '_');
    this.viewDate = date;
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
      });
  }

  public async refresh(): Promise<void> {
    this.makeCalendar();
   }

  private getDisplayTextSubmission(sub: Submission) {
    const creatorApproval = this.creatorApprovalsList.find(y => y.userId === sub.userId);
    return `${creatorApproval.creator.getSocialName()} - ${sub.dateToPost} - ${sub.platform} - ${sub.format}`;
  }

  private getDisplayTextPlannerContent(content: CreatorPlannerContent) {
    const creatorApproval = this.getCreatorApproval(content)
    return `${creatorApproval.creator.getSocialName()} - ${content.getPostAtWithoutTime()} - ${content.platform} - ${content.format}`;
  }

  //Funcion para utilizar un range en el html
  public counter(i: number) {
    if(i > 3) return new Array(3);
    return new Array(i);
  }

  public get calendarViewType(): typeof CalendarViewType {
    return CalendarViewType; 
  }


  public openSubmission(sub: Submission): void {
    const dialog = this.dialog.open(SubmissionDialogComponent, {
      width: '656px',
      data: {
        campaign: this.campaign,
        submission: sub,
        creators: this.creatorApprovals.map(value => value.creator),
        creatorApproval: this.creatorApprovals
      }
    });
    dialog.afterClosed().subscribe(() => {
      this.refresh();
    });
  }

  public openPlanner(content: CreatorPlannerContent): void {
    const creatorApproval = this.getCreatorApproval(content);
    const dialog = this.dialog.open(ContentPlannerDialogComponent, {
      width: '480px',
      data: {
        campaign: this.campaign,
        content,
        creatorApproval,
        planner: creatorApproval.planner.find(planner => planner.id === content.plannerId)
      }
    });
    dialog.afterClosed().subscribe(() => {
      this.refresh();
    });
  }
}
