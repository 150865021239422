import { Deserializable } from './deserializable';
import { Instagram } from './instagram.model';
import { Youtube } from './youtube.model';
import { ConnectInventory } from './connectInventory.model';
import { Creator } from './creator.model';
import { PerformanceSubmission, Submission } from './submission.model';
import { TikTok } from './tiktok.model';
import { SocialNetworks } from './SocialNetworks';
import { Campaign } from './campaign.model';
import { SocialUser } from './Interfaces/SocialUser';
import { Helper } from '../support/helper.support';
import { CalculatorPlanner } from './calculatorPlanner';
import { FormGroup } from '@angular/forms';
import { CreatorPlannerContent } from './CreatorPlannerContent.model';
import { CreatorFilter } from '../enum/creator-filter.enum';

export enum PlannerContentNames {
  FEEDS = 'feeds',
  REELS = 'reels',
  STORIES = 'stories',
  EXCLUSIVES = 'exclusives',
  MENTIONS = 'mentions',
  TIKTOK_VIDEOS = 'tiktokVideos',
};

export const PlannerContentNamesByPlatform = {
  instagram: [PlannerContentNames.FEEDS, PlannerContentNames.REELS, PlannerContentNames.STORIES],
  youtube: [PlannerContentNames.EXCLUSIVES, PlannerContentNames.MENTIONS],
  tiktok: [PlannerContentNames.TIKTOK_VIDEOS],
};

export class PlannerContent {
  feeds: number = null;
  reels: number = null;
  stories: number = null;
  exclusives: number = null;
  mentions: number = null;
  tiktokVideos: number = null;

  constructor(data: {[key in PlannerContentNames]?: number} = {}) {
    Object.assign(this, data);
  }

  clone() : PlannerContent {
    return new PlannerContent(this);
  }

  instagram() {
    return {
      feeds: this.feeds,
      reels: this.reels,
      stories: this.stories,
    };
  }

  youtube() {
    return {
      stories: this.stories,
      exclusives: this.exclusives,
    };
  }

  tiktok() {
    return {
      tiktokVideos: this.tiktokVideos,
    };
  }

  tikTok() {
    return this.tiktok();
  }
}

export class CreatorApprovalPlanner {
  id: number = null;
  platform: string;
  approval_id: number;
  approved = false;
  askedPrice: number = null;
  negotiatedPrice: number = null;
  agencyFee: number = null;
  profit: number = null;
  content = new PlannerContent();
  avgViews = new PlannerContent();
  avgReach = new PlannerContent();
  avgEngagementsAbs = new PlannerContent();
  plannerContents: CreatorPlannerContent[] = [];
  estimatedClicks: number = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }

  clone(): CreatorApprovalPlanner {
    const theClone = new CreatorApprovalPlanner(this);
    theClone.content = this.content.clone();
    theClone.avgViews = this.avgViews.clone();
    theClone.avgReach = this.avgReach.clone();
    theClone.avgEngagementsAbs = this.avgEngagementsAbs.clone();
    theClone.plannerContents = this.plannerContents.map(content => content.clone());
    return theClone;
  }

  static engagementAbsToPercent(engagementAbs: number, views: number): number {
    return (engagementAbs === null || views === null) ? null
      : parseFloat((engagementAbs * 100 / views).toFixed(2));
  }

  static engagementPercentToAbs(engagementPercent: number, views: number): number {
    return (engagementPercent === null || views === null) ? null
      : Math.round(engagementPercent * views / 100);
  }

  netNegotiatedPrice(): number {
    if (this.negotiatedPrice === null) {
      return null;
    }
    return (Number(this.negotiatedPrice) * 100 ) / (100 - (Number(this.agencyFee) + Number(this.profit)) );
  }

  netNegotiatedPriceIfContent(): number {
    return this.totalContent() > 0 ? this.netNegotiatedPrice() : 0;
  }

  views(): PlannerContent {
    const plannerContent = new PlannerContent();
    Object.keys(plannerContent).forEach((key) =>
      plannerContent[key] = this.avgViews[key] * this.content[key]
    );
    return plannerContent;
  }

  reach(): PlannerContent {
    const plannerContent = new PlannerContent();
    Object.keys(plannerContent).forEach(key =>
      plannerContent[key] = this.avgReach[key] * this.content[key]
    );
    return plannerContent;
  }

  engagements(): PlannerContent {
    const plannerContent = new PlannerContent();
    Object.keys(plannerContent).forEach(key =>
      plannerContent[key] = this.avgEngagementsAbs[key] * this.content[key]
    );
    return plannerContent;
  }

  costPerView(): PlannerContent {
    const plannerContent = new PlannerContent();
    Object.keys(plannerContent).forEach(key =>
      plannerContent[key] = this.views()[key] ? this.netNegotiatedPrice() / this.views()[key] : null
    );
    return plannerContent;
  }

  costPerReach(): PlannerContent {
    const plannerContent = new PlannerContent();
    Object.keys(plannerContent).forEach(key =>
      plannerContent[key] = this.reach()[key] ? this.netNegotiatedPrice() / this.reach()[key] : null
    );
    return plannerContent;
  }

  costPerEngagement(): PlannerContent {
    const plannerContent = new PlannerContent();
    Object.keys(plannerContent).forEach(key =>
      plannerContent[key] = this.engagements()[key] ? this.netNegotiatedPrice() / this.engagements()[key] : null
    );
    return plannerContent;
  }

  avgEngagementsPercent(): PlannerContent {
    const plannerContent = new PlannerContent();
    Object.keys(plannerContent).forEach(key =>
      plannerContent[key] = CreatorApprovalPlanner.engagementAbsToPercent(
        this.avgEngagementsAbs[key], this.avgViews[key])
    );
    return plannerContent;
  }

  costPerClick(): number {
    return this.estimatedClicks ? this.netNegotiatedPrice() / this.estimatedClicks : null;
  }

  totalContent(): number {
    return Helper.sum(Object.values(this.content));
  };

  totalReach(): number {
    return Helper.sum(Object.values(this.reach()));
  };

  totalViews(): number {
    return Helper.sum(Object.values(this.views()));
  }

  totalEngagements(): number {
    return Helper.sum(Object.values(this.engagements()));
  }

  totalCostPerView(): number {
    return this.totalViews() ? this.netNegotiatedPrice() / this.totalViews() : null;
  }

  totalCostPerEngagement(): number {
    return this.totalEngagements() ? this.netNegotiatedPrice() / this.totalEngagements() : null;
  }

  totalCostPerReach(): number {
    return this.totalReach() ? this.netNegotiatedPrice() / this.totalReach() : null;
  }

  static newPlanner(platform: string, creatorApproval: CreatorApproval) {
    const engPercentToAbs = CreatorApprovalPlanner.engagementPercentToAbs;
    const planner = new CreatorApprovalPlanner();
    planner.approval_id = creatorApproval.id;
    planner.platform = platform;
    planner.approved = false;
    planner.agencyFee = creatorApproval.campaign.agencyShippingCostFee;
    planner.profit = creatorApproval.campaign.profit;
    if (platform === 'instagram' && creatorApproval.instagram) {
      const instagram = creatorApproval.instagram;
      planner.avgViews.feeds = instagram.avgFeed;
      planner.avgViews.reels = instagram.avgReels;
      planner.avgViews.stories = instagram.avgStories;
      planner.avgReach.feeds = instagram.avgReachFeed;
      planner.avgReach.reels = instagram.avgReachReels;
      planner.avgReach.stories = instagram.avgReachStories;
      planner.avgEngagementsAbs.feeds = instagram.avgEngagementFeed;
      planner.avgEngagementsAbs.reels = instagram.avgEngagementReels;
      planner.avgEngagementsAbs.stories = instagram.avgEngagementStories;
    } else if (platform === 'youtube' && creatorApproval.youtube) {
      const youtube = creatorApproval.youtube;
      planner.avgViews.exclusives = youtube.avgVideoYoutube;
      planner.avgViews.mentions = youtube.avgVideoYoutube;
      planner.avgReach.exclusives = youtube.avgReachVideoYoutube;
      planner.avgReach.mentions = youtube.avgReachVideoYoutube;
      planner.avgEngagementsAbs.exclusives = youtube.avgEngagementVideoYoutube;
      planner.avgEngagementsAbs.mentions = youtube.avgEngagementVideoYoutube;
    } else if (platform === 'tiktok' && creatorApproval.tikTok) {
      const tiktok = creatorApproval.tiktok;
      planner.avgViews.tiktokVideos = tiktok.avgVideoTiktok;
      planner.avgReach.tiktokVideos = tiktok.avgReachVideoTiktok;
      planner.avgEngagementsAbs.tiktokVideos = tiktok.avgEngagementVideoTiktok;
    }
    return planner;
  }

  public deserialize(input: any): CreatorApprovalPlanner {
    this.id = Helper.numberOrNull(input.id);
    this.platform = input.platform;
    this.approval_id = Number(input.approval_id);
    this.askedPrice = Helper.numberOrNull(input.asked_price);
    this.negotiatedPrice = Helper.numberOrNull(input.negotiated_price);
    this.agencyFee = Helper.numberOrNull(input.agency_fee);
    this.profit = Helper.numberOrNull(input.profit);
    this.approved = !!input.approved;
    this.estimatedClicks = Helper.numberOrNull(input.estimated_clicks);
    this.content = new PlannerContent();
    this.content.feeds = Helper.numberOrNull(input.feeds);
    this.content.reels = Helper.numberOrNull(input.reels);
    this.content.stories = Helper.numberOrNull(input.stories);
    this.content.exclusives = Helper.numberOrNull(input.youtube_videos);
    this.content.mentions = Helper.numberOrNull(input.youtube_mentions);
    this.content.tiktokVideos = Helper.numberOrNull(input.tiktok_videos);
    this.avgViews = new PlannerContent();
    this.avgViews.feeds = Helper.numberOrNull(input.avg_feed);
    this.avgViews.reels = Helper.numberOrNull(input.avg_reels);
    this.avgViews.stories = Helper.numberOrNull(input.avg_stories);
    this.avgViews.exclusives = Helper.numberOrNull(input.avg_video_youtube);
    this.avgViews.mentions = Helper.numberOrNull(input.avg_mentions);
    this.avgViews.tiktokVideos = Helper.numberOrNull(input.avg_video_tiktok);
    this.avgReach = new PlannerContent();
    this.avgReach.feeds = Helper.numberOrNull(input.avg_reach_feed);
    this.avgReach.reels = Helper.numberOrNull(input.avg_reach_reels);
    this.avgReach.stories = Helper.numberOrNull(input.avg_reach_stories);
    this.avgReach.exclusives = Helper.numberOrNull(input.avg_reach_video_youtube);
    this.avgReach.mentions = Helper.numberOrNull(input.avg_reach_mentions);
    this.avgReach.tiktokVideos = Helper.numberOrNull(input.avg_reach_video_tiktok);
    this.avgEngagementsAbs = new PlannerContent();
    this.avgEngagementsAbs.feeds = Helper.numberOrNull(input.avg_engagement_feed);
    this.avgEngagementsAbs.reels = Helper.numberOrNull(input.avg_engagement_reels);
    this.avgEngagementsAbs.stories = Helper.numberOrNull(input.avg_engagement_stories);
    this.avgEngagementsAbs.exclusives = Helper.numberOrNull(input.avg_engagement_video_youtube);
    this.avgEngagementsAbs.mentions = Helper.numberOrNull(input.avg_engagement_mentions);
    this.avgEngagementsAbs.tiktokVideos = Helper.numberOrNull(input.avg_engagement_video_tiktok);
    this.plannerContents = input.planner_contents ? input.planner_contents.map(content => (new CreatorPlannerContent()).deserialize(content)) : null;
    
    return this;
  }

  public serialize() {
    return {
      platform: this.platform,
      approval_id: this.approval_id,
      approved: this.approved,
      asked_price: this.askedPrice,
      negotiated_price: this.negotiatedPrice,
      agency_fee: this.agencyFee,
      profit: this.profit,
      estimated_clicks: this.estimatedClicks,
      feeds: this.content.feeds,
      reels: this.content.reels,
      stories: this.content.stories,
      youtube_mentions: this.content.mentions,
      youtube_videos: this.content.exclusives,
      tiktok_videos: this.content.tiktokVideos,
      avg_feed: this.avgViews.feeds,
      avg_reels: this.avgViews.reels,
      avg_stories: this.avgViews.stories,
      avg_video_youtube: this.avgViews.exclusives,
      avg_mentions: this.avgViews.mentions,
      avg_video_tiktok: this.avgViews.tiktokVideos,
      avg_reach_feed: this.avgReach.feeds,
      avg_reach_reels: this.avgReach.reels,
      avg_reach_stories: this.avgReach.stories,
      avg_reach_video_youtube: this.avgReach.exclusives,
      avg_reach_mentions: this.avgReach.mentions,
      avg_reach_video_tiktok: this.avgReach.tiktokVideos,
      avg_engagement_feed: this.avgEngagementsAbs.feeds,
      avg_engagement_reels: this.avgEngagementsAbs.reels,
      avg_engagement_stories: this.avgEngagementsAbs.stories,
      avg_engagement_video_youtube: this.avgEngagementsAbs.exclusives,
      avg_engagement_mentions: this.avgEngagementsAbs.mentions,
      avg_engagement_video_tiktok: this.avgEngagementsAbs.tiktokVideos,
    };
  }
}

export class PlannerForm {

  planner: CreatorApprovalPlanner;
  form: FormGroup;

  constructor(planner: CreatorApprovalPlanner) {
    this.planner = planner;
  }

  setForm(form: FormGroup) {
    this.form = form;
  }

  static NESTED_PROP_SEPARATOR = '.';

  plannerToFormData() {
    return Helper.flattenObject(this.planner, PlannerForm.NESTED_PROP_SEPARATOR);
  }

  getPlannerValue(formProp: string): any {
    const [prop, subProp] = formProp.split(PlannerForm.NESTED_PROP_SEPARATOR);
    return subProp ? this.planner[prop][subProp] : this.planner[prop];
  }

  setPlannerValue(formProp: string, value: any){
    const [prop, subProp] = formProp.split(PlannerForm.NESTED_PROP_SEPARATOR);
    if (subProp) {
      this.planner[prop][subProp] = value;
    } else {
      this.planner[prop] = value;
    }
  }
}


export class Comment {
  public id: number;
  public ts: string;
  public username: string;
  public user_id: number;
  public comment: string;
  // public user_approval_id: number;
  public fontColor = 'unset';
  public commentColor = 'unset';
}


export class CreatorApproval implements Deserializable {
  instagram: Instagram;
  youtube: Youtube;
  tikTok: TikTok;
  id: number;
  campaignId: number;
  campaign?: Campaign;
  userId: number;
  youtubeId: string;
  requestTimestamp: string; // "2018-06-21 15:25:09.703" date??
  status: string; // possible enum
  name: string;
  gender: string;
  calc_selected: boolean;
  hasAudience = true;
  connectInventories: Array<ConnectInventory> = [];
  creator: Creator;
  submissions: Array<Submission> = [];
  submissionsPerformanceStats : Array<PerformanceSubmission> = [];
  userSubmissions: Array<Submission> = [];
  planner: CreatorApprovalPlanner[];
  aboutMe: string;
  creatorLevel: string;

  constructor() {
    return;
  }

  get tiktok(): TikTok{
    return this.tikTok;
  }

  isConfirmed(): boolean {
    return this.status === CreatorFilter.Confirmed;
  }

  getSubmissions(isPerformace = false) {
    return isPerformace ? this.submissionsPerformanceStats : this.submissions;
  }

  deserialize(input: any): this {
    this.id = input.id;
    this.campaignId = parseInt(input.campaign_id, 10);
    this.campaign = input.campaign ? new Campaign().deserialize(input.campaign) : null;
    this.userId = input.user_id && parseInt(input.user_id, 10);
    this.youtubeId = input.youtube_id;
    this.requestTimestamp = input.requested_time;
    this.status = input.status === 'Rejected' ? 'Canceled' : input.status;
    this.name = input.user?.firstname ? input.user.firstname + ' ' + (input.user.lastname ? input.user.lastname : '') : '-';
    this.gender = input.user?.gender ? input.user.gender : '-';
    if (input.creator) {
      this.instagram = input.creator.instagram ? new Instagram(input.creator.instagram) : null;
      this.youtube = input.creator.youtube ? new Youtube(input.creator.youtube) : null;
      this.tikTok = input.creator.tiktok ? new TikTok(input.creator.tiktok) : null;
    } else {
      this.instagram = input.instagram ? new Instagram(input.creator.instagram) : null;
      this.youtube = input.youtube ? new Youtube(input.creator.youtube) : null;
      this.tikTok = input.tiktok ? new TikTok(input.creator.tiktok) : null;
    }
    if (input.inventory) {
      input.inventory.forEach(x => this.connectInventories.push(ConnectInventory.deserialize(x)));
    }
    if (input.user) {
      input.user.instagram = input.instagram_user;
      input.user.youtube = input.youtube_user;
      input.user.tiktok = input.tiktok_user;
      input.user.category = input.category;
      this.creator = input.creator &&new Creator().deserialize(input.creator);
    }
    this.calc_selected = input.calc_selected;
    this.planner = input.planner ? input.planner.map(c => new CreatorApprovalPlanner().deserialize(c)) : [];
    this.userSubmissions = input.user_submissions ? input.user_submissions.map(s => new Submission().deserialize(s)) : [];
    this.aboutMe = input.about_me;
    this.creatorLevel = input.creator_level;
    return this;
  }

  getPlatform(platform: string) {
    return platform === 'instagram' ? this.instagram
    : platform === 'youtube' ? this.youtube
    : (platform === 'tiktok' || platform === 'tikTok') ? this.tikTok
    : null;
  }

  public hasInstagram(): boolean {
    return !!this.instagram;
  }

  public hasYoutube(): boolean {
    return !!this.youtube;
  }

  public hasTikTok(): boolean {
    return !!this.tikTok;
  }
  public getProfilePicture(socialNetwork: string = ''): string {
    if (socialNetwork.toLowerCase() === 'instagram') {
      return this.hasInstagram() ? this.instagram.profilePicture : '';
    } else if (socialNetwork.toLowerCase() === 'youtube') {
      return this.hasYoutube() ? this.youtube.profilePicture : '';
    }
    return this.hasInstagram() && this.instagram.profilePicture !== '' && this.instagram.profilePicture !== null ?
      this.instagram.profilePicture : (this.hasYoutube() && this.youtube.profilePicture !== '' && this.youtube.profilePicture !== '' ?
        this.youtube.profilePicture : '');
  }

  public getSocialNetworks(): SocialUser[] | String {
    return this.creator.socialNetworks ?? '';
  }

  public getFollowers(socialNetwork: SocialNetworks = null): number {
    if (socialNetwork) {
      if (socialNetwork === SocialNetworks.Instagram) {
        return this.hasInstagram() && !!this.instagram.audience && !!this.instagram.audience.followers ?
          this.instagram.audience.followers : 0;
      } else if (socialNetwork === SocialNetworks.YouTube) {
        return this.youtube.audience ? this.youtube.audience.subscriberCount ? this.youtube.audience.subscriberCount : 0 : 0;
      } else if (socialNetwork === SocialNetworks.TikTok) {
        return this.hasTikTok() && this.tikTok.audience ? this.tikTok.audience.followers : 0;
      }
    } else {
      if (this.hasInstagram()) {
        return this.hasInstagram() && !!this.instagram.audience && !!this.instagram.audience.followers ?
          this.instagram.audience.followers : 0;
      } else if (this.hasYoutube()) {
        return this.youtube.audience ? this.youtube.audience.subscriberCount : 0;
      } else if (this.hasTikTok()) {
        return this.tikTok.audience ? this.tikTok.audience.followers : 0;
      }
    }
  }

  public hasInstagramAudience(): boolean {
    return this.hasInstagram() && !!this.instagram.audience;
  }

  addToPlanner(planner: CreatorApprovalPlanner) {
    this.planner = this.planner.filter(p => p.platform !== planner.platform);
    this.planner.push(planner);
  }

  getPlanner(platform: string): CreatorApprovalPlanner {
    return this.planner.find(p => p.platform === platform);
  }

  getPlannerOrBuild(platform: string): CreatorApprovalPlanner {
    if (!this[platform]) {
      throw new Error(`creator approval doesnt have platform: ${platform}`);
    }
    return this.getPlanner(platform) ?? CreatorApprovalPlanner.newPlanner(platform, this);
  }

  getPlannersForCampaign(): CreatorApprovalPlanner[] {
    return this.campaign.getPlatformsForContents()
      .filter(platform => this[platform])
      .map(platform => this.getPlannerOrBuild(platform));
  }

  getCalculatorPlanner(): CalculatorPlanner {
    return new CalculatorPlanner('', this.getPlannersForCampaign());
  }

  getPlannerContents() {
    const contents = (this.planner || []).reduce(
      (prev, planner) => prev.concat(planner.plannerContents ?? []), [])
        .filter(x => x)
    return contents;
  }

  clone(): CreatorApproval {
    const newApproval = new CreatorApproval();
    newApproval.instagram = this.instagram;
    newApproval.youtube = this.youtube;
    newApproval.id = this.id;
    newApproval.campaignId = this.campaignId;
    newApproval.campaign = this.campaign;
    newApproval.userId = this.userId;
    newApproval.youtubeId = this.youtubeId;
    newApproval.requestTimestamp = this.requestTimestamp;
    newApproval.status = this.status;
    newApproval.name = this.name;
    newApproval.gender = this.gender;
    newApproval.hasAudience = this.hasAudience;
    newApproval.connectInventories = this.connectInventories;
    newApproval.creator = this.creator;
    newApproval.submissions = [];
    this.submissions.forEach(x => newApproval.submissions.push(x.clone()));
    newApproval.submissionsPerformanceStats = [];
    this.submissionsPerformanceStats.forEach(x => newApproval.submissionsPerformanceStats.push(x.clone()));
    newApproval.planner = this.planner;
    newApproval.userSubmissions = [];
    this.userSubmissions.forEach(x => newApproval.userSubmissions.push(x.clone()));
    this.aboutMe = this.aboutMe;
    this.creatorLevel = this.creatorLevel;
    return newApproval;
  }

}
